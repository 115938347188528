import React, { Component } from 'react'
import { Grid, TextField, Button, withStyles, Typography } from '@material-ui/core'
import validator from 'validator'

const styles = {
  actionArea: {
    marginTop: 20
  },
  title: {
    marginBottom: 20
  }
}

class RequestReset extends Component {
  state = {
    username: '',
    mobile: '',
    usernameValidation: {
      valid: false,
      touched: false,
      error: ' '
    },
    mobileValidation: {
      valid: false,
      touched: false,
      error: ' '
    },
    formValid: false
  }

  changeHandler = e => {
    const { value, id } = e.target
    this.setState({ [id]: value })
    this.validate(id, value)
  }

  blurHandler = e => {
    const { value, id } = e.target
    this.validate(id, value)
  }

  validate (id, value) {
    let valid
    switch (id) {
      case 'username':
        valid = validator.isEmail(value)
        this.setState({usernameValidation: {
            valid: valid,
            touched: true,
            error: valid ? ' ' : 'Tast inn <bruker>@rs.no'
          } })
        break
        case 'mobile':
          valid = validator.isMobilePhone(value, ['nb-NO'])
          this.setState({mobileValidation: {
              valid: valid,
              touched: true,
              error: valid ? ' ' : 'Tast inn et norskt mobilnummer'
            } })
        break
      default:
        break
    }
  }

  render () {
    const { username, mobile, classes } = this.props
    return (
      <React.Fragment>
        <form>
        <Typography variant={'h5'} className={classes.title} color="textPrimary" >
          Resett passord
        </Typography>
        <TextField
          id='username'
          error={!this.state.usernameValidation.valid && this.state.usernameValidation.touched}
          helperText={this.state.usernameValidation.error}
          value={username}
          onChange={(e) => { this.changeHandler(e) }}
          onBlur={e => this.blurHandler(e)}
          label='Bruker'
          margin='normal'
          fullWidth
          autoFocus
          variant="outlined"
        />
        <TextField
          id='mobile'
          error={!this.state.mobileValidation.valid && this.state.mobileValidation.touched}
          helperText={this.state.mobileValidation.error}
          value={mobile}
          onChange={(e) => { this.changeHandler(e) }}
          onBlur={e => this.blurHandler(e)}
          label='Mobilnummer'
          margin='normal'
          fullWidth
          variant="outlined"
        />

        <Grid container direction='row-reverse' className={classes.actionArea}>
          <Button
            // type="submit"
            size='large'
            variant='outlined'
            disabled={!(this.state.mobileValidation.valid && this.state.usernameValidation.valid)}
            color='primary'
            onClick={() => this.props.createRequest({username: this.state.username, mobile: this.state.mobile})}
          >
            Send meg kode
          </Button>
        </Grid>
        </form>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(RequestReset)
