import React from 'react'
import { Grid, Typography, withStyles, Button, MySnackbarContentWrapper, Snackbar, SnackbarContent, Icon } from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors';

const styles = {
  
  root: {
    height: '270px'
  },
  melding: {
    marginTop: 20
  },
  infoMelding: {
    marginTop: 40
  },
  actionArea: {
    
  }
}

function renderErrorMessage(errorMessage = '') {
  if (errorMessage.includes('0000052D')) {
    return (
      <Typography variant='body1' color="textSecondary">Passordet kan ikke inneholde brukernavnet, fornavnet eller etternavnet ditt.</Typography>
    )
  }
  
  else if (errorMessage.includes('0000001F')) {
    return (
      <Typography variant='body1' color="textSecondary">Unngå karakterene æøå i passordet. (Dette vil bli fikset, men inntil videre ikke benytt æøå i passordet)</Typography>
    )
  } else {
    return (
      <Typography variant='body1' color="textSecondary">{errorMessage}</Typography>
    )
  }
}

function Error (props) {
  const { classes } = props
  return (
    <React.Fragment>
      <div container direction='column' justify='space-between' className={classes.root}>
        <div>
          <Typography variant='h5' color="textPrimary">Feilmelding:</Typography>
        </div>
        <div className={classes.melding}>
          {renderErrorMessage(props.error)}
        </div>
        <div className={classes.infoMelding}>
          <Typography variant='caption' color="primary">Husk å oppgi bruker-ID i formatet <b>fornavn.mellomnavn.etternavn@rs.no</b> for ansatte eller <b>medlemsnummer@rs.no</b> for frivillige."</Typography>
        </div>
      </div>
      <Grid container direction="column" alignItems="flex-end">
      <Button size='large' variant='outlined' color='primary' onClick={() => props.retry()}>
            Prøv på nytt
          </Button>
      </Grid>
      
    </React.Fragment>
  )
}

export default withStyles(styles)(Error)
