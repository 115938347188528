import React from 'react'
import { Grid, Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PasswordResetWizard from './PasswordReset/PasswordResetWizard'
import queryString from 'query-string'

const styles = (theme) => ({
  root: {
    minHeight: '100vh',

    padding: theme.spacing.unit,
    [theme.breakpoints.down('sm')]: {},
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {}
  },
  logo: {
    height: 61,
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  logoBackground: {
    height: 110,
    backgroundColor: '#030E33',
    display: 'flex',
    justifyContent: 'center',
    margin: -1
  },
  content: {
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.down('sm')]: {
      minHeight: 300
    },
    [theme.breakpoints.up('md')]: {
      width: theme.spacing.unit * 60,
      minHeight: 300
    },
    [theme.breakpoints.up('lg')]: {
      width: theme.spacing.unit * 60,
      minHeight: 300
    }
  }
})

function MainView (props) {
  //const params = queryString.parse(props.location.search)
  //const callbackUrl = props.location.search.slice(1)
  console.log('referrer', document.referrer)
  const callbackUrl = document.referrer //.split(/\?(.+)/)[1]
  console.log('callback', callbackUrl)
  const { classes } = props
  function showEnvironment () {
    if (process.env.NODE_ENV !== 'production') {
      return <h6>Running in {process.env.NODE_ENV} mode</h6>
    } else {
      return <h6>Redningsselskapet 2019</h6>
    }
  }
  return (
    <Grid justify='center' alignItems='center' direction='column' container className={classes.root}>
      <Paper>
        <div className={classes.logoBackground}>
          <img src='/images/rs_logo_blue.png' alt='Redningsselskapet' className={classes.logo} />
        </div>
        <div className={classes.content}>
          <PasswordResetWizard callbackUrl={callbackUrl}/>
        </div>
      </Paper>
      {showEnvironment()}
    </Grid>
  )
}

export default withStyles(styles)(MainView)
