import React, { Component } from 'react'
import RequestReset from './RequestReset'
import SetPassword from './SetPassword'
import ad from '../../api/ad'
import Success from './Success'
import Error from './Error'
import Loader from './Loader'
import queryString from 'query-string'

export default class PasswordResetWizard extends Component {
  state = {
    step: 0,
    username: '',
    error: '',
    callbackUrl: ''
  }

  componentDidMount () {
    this.setState({callbackUrl: this.props.callbackUrl})
  }

  handleCreateRequest = ({ username, mobile }) => {
    this.setState({ step: null })
    ad
      .post(`/startpassreset?callbackUrl=${this.state.callbackUrl}`, {
        username,
        last4DigitMobile: mobile.slice(-4)
      })
      .then((response) => {
        if (response.status === 200) {
          this.setState({ username })
          this.setState({ step: 1 })
        }
      })
      .catch((err) => {
        if (err.response) {
          this.setState({ error: err.response.data.message })
        } else {
          this.setState({ error: 'Æsj! Noe er galt i programmet.' })
        }
        this.setState({ step: 3 })
      })
  }

  handleSetPassword = ({ code, password }) => {
    this.setState({ step: null })
    ad
      .post('/completePassreset', {
        code: code,
        password: password
      })
      .then((response) => {
        if (response.status === 200) {
          this.setState({ step: 2 })
        }
      })
      .catch((err) => {
        if (err.response) {
          this.setState({ error: err.response.data.message })
        } else {
          this.setState({ error: 'Æsj! Noe gikk galt i programmet.' })
        }
        this.setState({ step: 3 })
      })
  }

  render () {
    const { step } = this.state
    switch (step) {
      case 0:
        return <RequestReset createRequest={this.handleCreateRequest} />
      case 1:
        return <SetPassword username={this.state.username} setPassword={this.handleSetPassword} />
      case 2:
        return <Success callbackUrl={this.state.callbackUrl}/>
      case 3:
        return <Error error={this.state.error} retry={() => this.setState({ step: 0 })} />
      default:
        return <Loader />
    }
  }
}
