import React, { Component } from 'react'
import { createMuiTheme, MuiThemeProvider, CssBaseline } from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors'
import 'typeface-roboto'
import { BrowserRouter, Route } from 'react-router-dom'
import MainView from './MainView'

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: blueGrey
  }
})

export default class App extends Component {
  render () {
    return (
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Route path='/' component={(props) => <MainView {...props} />} />
        </MuiThemeProvider>
      </BrowserRouter>
    )
  }
}
