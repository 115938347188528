import React from 'react'
import { Grid, LinearProgress } from '@material-ui/core'

export default function Loader () {
  return (
    <React.Fragment>
      <Grid style={{flexGrow: 1}}>
      <LinearProgress />
      </Grid>
    </React.Fragment>
  )
}
