import React, { Component } from 'react'
import { Grid, TextField, Button, withStyles, Typography } from '@material-ui/core'
import validator from 'validator'
import PasswordValidator from 'password-validator'

const styles = {
  actionArea: {
    marginTop: 20
  },
  title: {
    marginBottom: 20
  }
}

const passwordSchema = new PasswordValidator()
    .is()
    .min(8) // Minimum length 8
    .is()
    .max(100) // Maximum length 100
    .has().uppercase() // Must have uppercase letters
    .has().lowercase() // Must have lowercase letters
    .has().digits() // Must have digits


class RequestReset extends Component {
  state = {
    password: '',
    code: '',
    codeValidation: {
      valid: false,
      touched: false,
      error: ' '
    },
    passwordValidation: {
      valid: false,
      touched: false,
      error: ' '
    },
    formValid: false
  }

  changeHandler = (e) => {
    const { value, id } = e.target
    this.setState({ [id]: value })
    this.validate(id, value)
  }

  blurHandler = (e) => {
    const { value, id } = e.target
    this.validate(id, value)
  }

  validate (id, value) {
    let valid
    switch (id) {
      case 'code':
        valid = !validator.isEmpty(value)
        this.setState({
          codeValidation: {
            valid: valid,
            touched: true,
            error: valid ? ' ' : 'Tast inn kode sendt på sms'
          }
        })
        break
      case 'password':
        valid = passwordSchema.validate(value)
        this.setState({
          passwordValidation: {
            valid: valid,
            touched: true,
            error: valid ? ' ' : 'Tast inn passord (8 tegn som inneholder minimum en stor bokstav og et tall)'
          }
        })
        break
      default:
        break
    }
  }

  render () {
    const { code, password, classes } = this.props
    return (
      <React.Fragment>
        <Typography variant={'h5'} className={classes.title} color="textPrimary">
          Sett nytt passord
        </Typography>
        <TextField
          id='code'
          error={!this.state.codeValidation.valid && this.state.codeValidation.touched}
          helperText={this.state.codeValidation.error}
          value={code}
          onChange={(e) => {
            this.changeHandler(e)
          }}
          onBlur={(e) => this.blurHandler(e)}
          label='Kode sendt på sms'
          margin='normal'
          fullWidth
          autoFocus
          variant='outlined'
          autoComplete="new-password" 
        />
        <TextField
          id='password'
          error={!this.state.passwordValidation.valid && this.state.passwordValidation.touched}
          helperText={this.state.passwordValidation.error}
          value={password}
          onChange={(e) => {
            this.changeHandler(e)
          }}
          onBlur={(e) => this.blurHandler(e)}
          label='Nytt passord'
          margin='normal'
          fullWidth
          type='password'
          variant='outlined'
          autoComplete="new-password" 
        />

        <Grid container direction='row-reverse' className={classes.actionArea}>
          <Button
            size='large'
            variant='outlined'
            disabled={!(this.state.codeValidation.valid && this.state.passwordValidation.valid)}
            color='primary'
            onClick={() => this.props.setPassword({code: this.state.code, password: this.state.password})}
          >
            Bekreft
          </Button>
        </Grid>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(RequestReset)
