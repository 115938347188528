import React from 'react'
import { Grid, LinearProgress, Typography } from '@material-ui/core'

export default function Success(props) {
  return (
    <React.Fragment>
      <Grid container alignContent='center' alignItems='center' direction='column'>
        <div style={{ marginTop: 40 }}>
          <Typography variant='h5'>Passord er endret!</Typography>
        </div>
      </Grid>
    </React.Fragment>
  )
}
